import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import CreateTopicContext from './context/TopicContext';
import PageWrapper from "components/PageWrapper";
import StepFormWrapper from "components/StepFormWrapper";
import ProblemStatementForm from "./Steps/ProblemStatementForm";
import SituationAndComplicationsForm from "./Steps/SituationAndComplicationsForm";
import StakeholdersForm from "./Steps/Stakeholders/StakeholdersForm";
import RatingForm from "./Steps/Rating/RatingForm";
import KeyResultsForm from "./Steps/KeyResultsConstraints/KeyResultsMainForm";
import TopicDefinitionOverview from "./Overview/TopicDefinitionOverview";

import { alertActionTypes } from 'constants/alert.action.types';
import { updateProblemById } from 'actions/problem.update.actions';
import { uploadFile } from 'actions/file.upload.actions';
import { deleteFile } from 'actions/file.delete.actions';

function DeepTopicForm({
    updateProblemById,
    uploadFile,
    deleteFile,
}) {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const ctx = useContext(CreateTopicContext);

    const { t } = useTranslation(['problemDefinition']);
    const { projectId } = useParams()
    const [isValid, setIsvalid] = useState(false);
    const [editModeActive, setEditModeActive] = useState(null); // only compatibility wiht syncDocuments

    let [currentStep, setCurrentStep] = useState(1);

    const problemId = location.search.replace('?topicId=', '');
    

    const handleValidation = (state, stepRef) => {
        setIsvalid(state);
        // Update the status of the step in stepList based on the validation state
        const updatedStepList = stepList.map(step => {
            if (step.ref === stepRef) {
                return {
                    ...step,
                    state: state ? 'complete' : 'current'
                };
            }
            return step;
        });
        setStepList(updatedStepList);
    };

    const [stepList, setStepList] = useState([
        {
            name: t('1-step-desc', 'Statement'), ref: "statement", href: '#', state: 'null',
            form: <ProblemStatementForm handleValidation={handleValidation} isDeepTopic/>, isActive: true, isCurrent: true,
        },
        { name: t('1-step-con', 'Context'), href: '#', state: 'null', form: <SituationAndComplicationsForm isDeepTopic /> },
        { name: t('1-step-sta', 'Stakeholders'), href: '#', state: 'upcoming', form: <StakeholdersForm /> },
        { name: t('1-step-rat', 'Rating'), href: '#', state: 'upcoming', form: <RatingForm /> },
        { name: t('1-step-key', 'Key Results & Constraints'), href: '#', state: 'upcoming', form: <KeyResultsForm /> },
        { name: 'Overview', href: '#', state: 'upcoming', form: <TopicDefinitionOverview editOnly={true} isDeepTopic/> },
    ]);

    // TODO add filter for active steps [.filter(step => step.isActive)]
    // TODO this needs to be edited once the correct filters are in the 'community overview'
    const activeSteps = stepList;

    // TODO: the same function is used in TopicDefinitionOverview
    // sync documents
    function syncDocuments() {
        // local copy of the original data
        let data = {...ctx.data}

        const documentUploadList = ctx.data.documentList?.filter(t => t.status === 'UPLOAD_PENDING');
        const documentDeleteList = ctx.data.documentList?.filter(t => t.status === 'DELETE_PENDING');
        const documentKeepListOrig = ctx.data.documentList?.filter(t => t.status !== 'UPLOAD_PENDING' && t.status !== 'DELETE_PENDING');

        const uploadPromises = documentUploadList?.map(doc =>
            uploadFile(problemId, doc.filename, doc.file)
                .then(response => {
                    return Promise.resolve({ ...doc, status: 'UPLOADED', response: response });
                })
                .catch(error => {
                    return Promise.reject({ ...doc, status: 'UPLOAD_ERROR', response: error });
                })
        );

        const deletePromises = documentDeleteList?.map(doc =>
            deleteFile(problemId, doc.filename)
                .then(response => {
                    return Promise.resolve({ ...doc, status: 'DELETED', response: response });
                })
                .catch(error => {
                    return Promise.reject({ ...doc, status: 'DELETE_ERROR', response: error });
                })
        );

        // Wait for all uploads to complete and return the updated document list
        Promise.allSettled(uploadPromises.concat(deletePromises)).then(results => {

            const fulfilled_upload = results.filter(result => result.status === 'fulfilled' && result?.value?.status === 'UPLOADED').map(result => result?.value);
            const fulfilled_delete = results.filter(result => result.status === 'fulfilled' && result?.value?.status === 'DELETED').map(result => result?.value);
            const rejected_upload = results.filter(result => result.status === 'rejected' && result?.reason?.status === 'UPLOAD_ERROR').map(result => result?.reason);
            const rejected_delete = results.filter(result => result.status === 'rejected' && result?.reason?.status === 'DELETE_ERROR').map(result => result?.reason);

            // excluding from keep list DELETED files
            const documentKeepList = documentKeepListOrig?.filter((d) => {
                return fulfilled_delete?.every((t) => {
                    return t.id !== d.id;
                });
            });

            const documentList = documentKeepList.concat(fulfilled_upload, rejected_upload, rejected_delete)
            
            data['documentList'] = documentList
            // sync data and ctx.data
            ctx.itemHandler('documentList', documentList);

            // sync data with backend
            delete data['documentTmpList']
            updateProblemById(problemId, data).then(
                response => {
                    setEditModeActive(!editModeActive)
                    if (rejected_upload?.length > 0 || rejected_delete?.length > 0) {
                        dispatch({type: alertActionTypes.ERROR, payload: 'some files could not be uploaded / deleted, please redo'})
                    }
                    history.push(`/clusters/${projectId}/problems/${problemId}`)

                })
        });
    }


    const onSave = () => {
        syncDocuments()
    };

    return (
        <PageWrapper>
            <StepFormWrapper
                stepList={stepList}
                currentStep={setCurrentStep}
                onSave={onSave}
                saveNow={true}
                isValid={true}
                buttonGroupId={'buttonGroup-problemForm'}
                backLink={`/clusters/${projectId}`}>
                <div className='h-full overflow-x-visible'>
                    <div className='py-4 max-w-7xl'>
                        {/* Container for Forms */}
                        <div id='NewProblemFormSectionContainer' className='py-4 pl-1'>
                            {activeSteps[currentStep - 1].form}
                        </div>
                    </div>
                </div>
            </StepFormWrapper>
        </PageWrapper>
    );
}

DeepTopicForm.propTypes = {
    updateProblemById: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
    updateProblemById,
    uploadFile,
    deleteFile
})(DeepTopicForm);