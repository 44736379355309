import { useLocation } from 'react-router-dom';
import { faArrowRight, faCheck, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RadialProgressBar from "components/Elements/RadialProgressBar";
import FlagForCollapsableContent from "components/FlagForCollapsableContent";
import { useTranslation } from 'react-i18next';

function DeepDiveCollapsableList({ id }) {
    const location = useLocation();
    const { t } = useTranslation(['problemDefinition'])
    // TODO add real list #86byktrwe
    // TODO real url should lead to overview OR into the active progress depending on the state (open, wip, done)
    //
    const modules = [
        { name: t('deepTopic', 'Refined Topic'), url: `${location.pathname}/problems/${id}`, state: 'OPEN' },
        // TODO deep dive loads without data #86c0vwpe3
        // { name: 'Problem Definition', url: `${location.pathname}/problems/new?topicId=${id}`, state: 'OPEN' }, 
        // { name: 'Problem Structure', url: `${location.pathname}/problems/${id}?id=problemStructure`, state: 'WIP' },
        // { name: 'Prioritized Issues', url: `${location.pathname}/problems/${id}?id=issues`, state: 'OPEN' },
        { name: 'Executive Summary', url: `${location.pathname}/problems/${id}?id=communicate`, state: 'OPEN' },
        //TODO add 'Protocol' 
    ]
    const collapseId = 'collapse' + id

    // calculation for cake display
    const calculateProgress = (modules) => {
        const totalModules = modules.length;
        const doneModules = modules.filter(module => module.state === 'DONE').length;
        const progressPercentage = (doneModules / totalModules) * 100;
        return progressPercentage;
    };

    const progress = calculateProgress(modules)

    const iconClass = ''

    return (
        <div id='deepDiveCollapsableList'>
            <div className="flex items-center">
                <FlagForCollapsableContent text={'Deep Dive'} collapseId={collapseId} showChevron />
                {/* {progress > 0 && <RadialProgressBar progress={progress} />} */}
            </div>
            <div className="collapse" id={collapseId}>
                {modules.map((module, index) => (
                    <p key={index} className="flex items-center pl-2 hover:bg-primary-100 w-5/6 rounded-md">
                        <a className="w-[190px]" href={module.url}>{module.name}</a>
                        {module.state === 'DONE' && <FontAwesomeIcon className={`${iconClass} text-green-600`} icon={faCheck} />}
                        {module.state === 'WIP' && <FontAwesomeIcon className={`${iconClass} text-primary-600`} icon={faPencil} />}
                        {module.state === 'OPEN' && <FontAwesomeIcon className={`${iconClass} text-gray-400`} icon={faArrowRight} />}
                    </p>
                ))}
            </div>
        </div>
    );
}

export default DeepDiveCollapsableList;