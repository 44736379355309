import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { parse, isValid, format } from 'date-fns';
import PropTypes from 'prop-types';

registerLocale('de', de);

function DatePicker({ value, onChange, viewOnly }) {
    const [selectedDate, setSelectedDate] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [displayDate, setDisplayDate] = useState(null)

    useEffect(() => {
        setSelectedDate(value)
        if(value) {
            setDisplayDate(format(value, 'dd.MM.yyyy'))
        } else {
            setDisplayDate(null)
        }
    }, [value]);

    const handleInputChange = (e) => {
        const value = e.target.value;

        // parsing input
        const parsedDate = parse(value, 'dd.MM.yyyy', new Date());
        if (isValid(parsedDate)) {
            setSelectedDate(parsedDate);
            setStartDate(parsedDate);
        }
    };

    const onCalendarChange = (date) => {
        setSelectedDate(date);
        setStartDate(date);
        onChange(date);
        const formattedDate = format(date, 'dd.MM.yyyy');
        setDisplayDate(formattedDate);

    }

    return (
        <div className="relative max-w-sm">
            {viewOnly
                ? <label className="block w-[196px]">{displayDate}</label>
                : <ReactDatePicker
                    selected={selectedDate}
                    onChange={onCalendarChange}
                    onInputChange={handleInputChange}
                    dateFormat="dd.MM.yyyy"
                    locale="de"
                    placeholderText="Datum auswählen"
                    startDate={startDate}
                    className="bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary-500 focus:border-primary-500 block w-full px-3 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    monthClassName='bg-green-300'
                    dayClassName={(date) =>
                        date.toDateString() === new Date().toDateString()
                            ? 'bg-primary-200 text-white rounded-md'
                            : 'text-gray-300'
                    }
                />
            }
        </div>
    );
}

DatePicker.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    viewOnly: PropTypes.bool
}

export default DatePicker;