const executiveSummaryDict = {
    // Guide
    "1-g1h": "Wodurch zeichnet sich eine Zusammenfassung bzw. ein Executive Summary aus?",
    "1-g1t": "Die Zusammenfassung bzw. das Executive Summary sollte die Synthese des gesamten Problemlösungsprozesses abbilden. Sie sollte die Ausgangslage, die wichtigsten Erkenntnisse und vor allem den Lösungsraum des Problems enthalten, sowie die grundlegende Logik mit Argumenten und Belegen zur Untermauerung.",
    "1-g2h": "Wann bietet sich eher ein top-down\"-Stil an?\"",
    "1-g2t": "Das Executive Summary beginnt mit Ihrem Leitgedanken zu Lösungsraum, Ausgangslage und Erkenntnissen. Erst danach folgen die grundlegende Logik sowie Argumente und Belege zur Untermauerung. Dies ist ein eher managementorientierter Kommunikationsstil, da Sie mit dem wichtigsten Teil beginnen, nämlich Ihrem Leitgedanken, der die Lösung des Problems enthält. Dies ist oft ein effektiver Stil, aber Sie sollten dennoch prüfen, ob er für Ihr Publikum geeignet ist.",
    "1-g3h": "Wann bietet sich eher ein bottom-up\"-Stil an?\"",
    "1-g3t": "Das Executive Summary beginnt mit einer Beschreibung der Ausgangslage und wesentlichen Erkenntnissen und leitet dann auf den Lösungsraum hin, gestützt auf die grundlegende Logik sowie Argumente und Belege zur Untermauerung. Dieser Kommunikationsstil eignet sich für ein Publikum, das sich zunächst ein umfassenderes Bild vom Kontext machen möchte, bevor es mit Ihrem Lösungsraum des Problems konfrontiert wird. Dieser Stil wird häufig im akademischen Bereich oder bei Zuhörern mit sehr unterschiedlichen persönlichen oder beruflichen Hintergründen verwendet."
}

export default executiveSummaryDict;