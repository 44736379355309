const executiveSummaryActionTypes = {
    GET_EXECUTIVE_SUMMARY_REQUEST: 'GET_EXECUTIVE_SUMMARY_REQUEST',
    GET_EXECUTIVE_SUMMARY_SUCCESS: 'GET_EXECUTIVE_SUMMARY_SUCCESS',
    GET_EXECUTIVE_SUMMARY_FAILURE: 'GET_EXECUTIVE_SUMMARY_FAILURE',

    UPDATE_EXECUTIVE_SUMMARY_REQUEST: 'UPDATE_EXECUTIVE_SUMMARY_REQUEST',
    UPDATE_EXECUTIVE_SUMMARY_SUCCESS: 'UPDATE_EXECUTIVE_SUMMARY_SUCCESS',
    UPDATE_EXECUTIVE_SUMMARY_FAILURE: 'UPDATE_EXECUTIVE_SUMMARY_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'
};

export { executiveSummaryActionTypes };