const problemDefinitionDict = {
    //////////////////////// Start OF 'Create Problem'
    // *** Create Problem ***
    "1-prev": "Vorschau",
    // ** Steps **
    "1-step-desc": "Beschreibung",
    "1-step-con": "Kontext",
    "1-step-sta": "Stakeholder",
    "1-step-rat": "Einordnung",
    "1-step-key": "Key Results und Randbedingungen",
    "deepTopic": "Themenbeschreibung",
    "": "",
    // ** Problem Statement ** > 1-ps-
    "1-ps-title": "Titel & Typ",
    "1-ps-hl": "Ihr Thema",
    "1-ps-placeholder": "z.B. CO2 Reduzierung in Schulen",
    "1-ps-prompt": "Thema & Status",
    "1-ps-desc": "z.B. Problemstellung, Idee, Forschungsfrage, Use Case, Designziel, …",
    "1-ps-confidentialTitel": "Schutzstufe",
    "1-ps-confidentialTooltip": "Bitte definieren Sie für das Endprodukt „Problemdefinition“, das Sie derzeit erstellen, die erforderliche Schutzstufe.",
    "1-ps-conf": "Vertraulichkeitslevel",
    "1-ps-confi1": "Streng vertraulich",
    "1-ps-confi2": "Vertraulich ",
    "1-ps-confi3": "Intern",
    "1-ps-confi4": "Öffentlich",
    "1-ps-confi1tooltip": "Z.B. Gesundheitsdaten, dienstliche Beurteilungen",
    "1-ps-confi2tooltip": "Z.B. Projektergebnisse ",
    "1-ps-confi3tooltip": "Z.B. Rechercheergebnisse ",
    "1-ps-confi4tooltip": "Z.B. Marketingflyer",
    "1-ps-visibilityTitel": "Sichtbarkeit",
    "1-ps-visibilityTooltip": "Hier legen Sie fest, wer Ihren Input sehen und kommentieren darf.",
    "1-ps-visib1": "Plattform",
    "1-ps-visib2": "Diese und weitere Communities",
    "1-ps-visib3": "Diese Community",
    "1-ps-visib4": "Einzelpersonen",
    "1-ps-visib1tooltip": "Sichtbar für alle registrierten Nutzerinnen und Nutzer auf der Plattform",
    "1-ps-visib2tooltip": "Sichtbar für Mitglieder dieser und weiterer von Ihnen ausgewählter Communities",
    "1-ps-visib3tooltip": "Sichtbar nur für alle Mitglieder dieser Community",
    "1-ps-visib4tooltip": "Sichtbar nur für Personen, die Sie persönlich benennen",
    "area1": "Marktsegment",
    "area2": "Wertschöpfungsschritt",
    "1-ps-tags": "Schlagworte",
    "1-ps-noSelect": "-",
    "1-ps-linkList": "Relevante Links",
    "1_ps_topicState1": "Offen",
    "1_ps_topicState2": "Implementiert",
    // Tipps
    "1-ps-1t": "Spezifisch",
    "1-ps-1d": "Was genau möchten Sie erreichen, z.B. „den NPS erhöhen“?",
    "1-ps-2t": "Messbar & Realistisch",
    "1-ps-2d": "Setzen Sie ein quantitatives und realistisches Ziel, z.B. „um 2 %-Pkt.“",
    "1-ps-3t": "Zeitgebunden",
    "1-ps-3d": "Setzen Sie einen Zeitrahmen, innerhalb dessen das Ziel erreicht werden soll, z.B. „innerhalb von 6 Monaten“",
    "1-ps-4t": "Ergebnisoffen",
    "1-ps-4d": "Vermeiden Sie eine Lösungsvorgabe. Lassen Sie Raum für Kreativität",
    "1-ps-5t": "Wertbeitragend",
    "1-ps-5d": "Stellen Sie einen möglichst direkten Beitrag zu den übergeordneten Unternehmenszielen sicher",
    "1-ps-6t": "Inspirierend",
    "1-ps-6d": "Ihre Problemstellung sollte ein Team motivieren, das Problem zu lösen",
    "1-ps-info": `Beispiel: Wie können wir den Ausstoß an Treibhausgasen unseres Gebäudes bis 2023 um 25 % reduzieren?`,
    // Guide
    "1-ps-g1h": "Wie formuliert man eine Problemstellung?",
    "1-ps-g1t": `Eine Problemstellung sollte ergebnisorientiert, spezifisch, messbar, realistisch und zeitgebunden sein; typischerweise wird die Frageform genutzt. Lassen Sie Raum für Kreativität und stimmen Sie sie mit anderen Bereichen Ihrer Organisation ab. \n
    Beispiel: \"Wie können wir den CO2-Fußabdruck unserer Schule innerhalb eines Jahres um mindestens 25% im Vergleich zum Basisjahr 2020 verringern?\"`,
    "1-ps-g2h": "Wie formuliert man einen Use Case bzw. Anwendungsfall?",
    "1-ps-g2t": `Sagen Sie auf einer hohen Ebene, wer die Lösung verwenden wird, wofür sie verwendet wird und wie die Benutzer sie verwenden werden. \n
    Beispiel: \"Ein Versicherer möchte ein internes Suchtool entwickeln, das Call-Center-Agenten dabei hilft, die Antwortzeit zu verkürzen und die Qualität zu verbessern, indem es spezifische, zeitnahe Angaben zu anspruchsvollen Kundenanfragen liefert\"`,
    "1-ps-g3h": "Wie formuliert man ein POV-Statement (\"Point of view/Blickwinkel\") und ein Designziel?",
    "1-ps-g3t": `Definieren Sie, wer die Nutzerpersona ist, was sie braucht und was die Gründe dafür sind. Versuchen Sie, konsequent aus dem Blickwinkel der Persona zu denken. Stellen Sie dann eine Frage, die Raum für Kreativität lässt, aber spezifisch auf das Designziel ausgerichtet ist. \n
    Beispiel: \"Ein Call Center-Mitarbeiter braucht Abwechslung in Bezug auf die Stimmung und den Inhalt von Kundenanfragen, weil sein Engagement und damit die Kundenzufriedenheit mit vielseitiger Arbeit steigen wird. Wie können wir also den Facettenreichtum in der Arbeit von Call Center-Mitarbeitenden erhöhen?\"`,
    "1-ps-g4h": "Wie formuliert man eine Forschungsfrage?",
    "1-ps-g4t": `Formulieren Sie die Forschungsfrage zielgerichtet, machbar, neuartig, relevant, systematisch, interessant; berücksichtigen Sie ethische Aspekte`,
    "1-ps-g5h": "Wie formuliert man eine Idee?",
    "1-ps-g5t": `Formulieren Sie die Idee zielgerichtet, machbar, innovativ, relevant, systematisch, interessant; berücksichtigen Sie ethische Aspekte`,
    "1-ps-g6h": "Was bedeutet die Sichtbarkeit eines Themas?",
    "1-ps-g6t": `Legen Sie fest, wer Ihren Beitrag lesen und kommentieren darf. Sie können mehrere Communities auswählen, um Ihren Beitrag zu teilen; deren Angehörige können von innerhalb oder außerhalb Ihrer Organisation kommen. Seien Sie daher bitte vorsichtig`,
    "1-ps-g7h": "Warum Schutzstufen?",
    "1-ps-g7t": `Schutzstufen sind ein Instrument zur Gewährleistung des Schutzes personenbezogener Daten und der Rechte an geistigem Eigentum, einschließlich Verschlusssachen wie Geschäftsgeheimnisse. Die unangemessene Offenlegung vertraulicher Informationen kann zu physischem, materiellem oder immateriellem Schaden für natürliche oder juristische Personen führen, z.B. wenn die Verarbeitung zu finanziellen Verlusten, zur Schädigung des Rufs oder der Wettbewerbsposition oder zu anderen erheblichen wirtschaftlichen oder sozialen Nachteilen führen kann`,
    "1-ps-g8h": "Wie hängen Schutzstufen und Datenschutz zusammen?",
    "1-ps-g8t": `Der Schutz von Geschäftsgeheimnissen als Teilmenge geistiger Eigentumsrechte sowie der Schutz von personenbezogenen Daten überlagern sich häufig, auch wenn die Schutzrichtungen sich unterscheiden`,
    "1-ps-g9h": "Wann ist die Vertraulichkeitsstufe \"streng vertraulich\" anwendbar?",
    "1-ps-g9t": `Diese Einstufung gilt für streng vertrauliche Informationen und/oder besondere Kategorien personenbezogener Daten (Art. 9 DSGVO), die durch eine stark eingeschränkte Zugangspolitik geschützt sind, die nur für eine streng definierte Anzahl von namentlich aufgeführten Personen gilt. Die unangemessene Weitergabe an Dritte ist verboten. Es ist zu erwarten, dass die unangemessene Weitergabe einen außergewöhnlich schweren Schaden verursachen würde. Beispiele: Besondere Kategorien von personenbezogenen Daten, Passwörter`,
    "1-ps-g10h": "Wann ist die Vertraulichkeitsstufe \"vertraulich\" anwendbar?",
    "1-ps-g10t": "Diese Einstufung gilt für vertrauliche Informationen und/oder personenbezogene Daten (Art. 9 DSGVO), die durch eine Politik des eingeschränkten Zugangs geschützt sind, die für eine bestimmte Gruppe von Personen gilt. Die unangemessene Weitergabe an Dritte ist verboten. Die unangemessene Offenlegung könnte nach vernünftigem Ermessen einen schweren bis mittleren Schaden verursachen. Beispiele: personenbezogene Daten, Produktentwicklungsdokumentationen",
    "1-ps-g11h": "Wann ist die Vertraulichkeitsstufe \"intern\" anwendbar?",
    "1-ps-g11t": "Diese Einstufung gilt für interne Informationen und/oder personenbezogene Daten (Art. 9 DSGVO), die durch eine einfache Zugriffspolitik geschützt sind. Die unangemessene Weitergabe an Dritte ist verboten. Die unangemessene Offenlegung könnte nach vernünftigem Ermessen einen leichten Schaden verursachen. Beispiele: interne Arbeitsanweisungen",
    "1-ps-g12h": "Wann ist die Vertraulichkeitsstufe \"öffentlich\" anwendbar?",
    "1-ps-g12t": "Diese Einstufung gilt für öffentliche Informationen oder Informationen, die zur Veröffentlichung bestimmt sind. Es gibt keine Einschränkungen für die Handhabung. Es ist zu erwarten, dass eine unangemessene Offenlegung keinen oder keinen erheblichen Schaden verursacht. Beispiel: Marketing-Flyer",
    "1-ps-g13h": "Welche Konsequenzen ergeben sich aus der gewählten Schutzstufe?",
    "1-ps-g13t": `Je nach Wahrscheinlichkeit des Schadenseintritts müssen die Verschlusssachen durch technische und organisatorische Maßnahmen geschützt werden, um ein dem Risiko angemessenes Maß an Sicherheit zu gewährleisten. Risiken können sich aus der zufälligen oder unrechtmäßigen Zerstörung, dem Verlust, der Veränderung, der unbefugten Weitergabe oder dem Zugriff auf übermittelte, gespeicherte oder anderweitig verarbeitete personenbezogene Daten ergeben (vgl. Art. 32 (2) DSGVO)`,
    "1-ps-g14h": "Warum Marktsegmente definieren?",
    "1-ps-g14t": `Marktsegmente zeigen auf, für welche Branchen und Teilbranchen das Thema relevant ist. Sie helfen dann, relevante Experten und weitere Ressourcen im jeweiligen Feld sowie Synergiepotenziale zu identifizieren`,
    "1-ps-g15h": "Warum Wertschöpfungskettenschritte definieren?",
    "1-ps-g15t": `Wertschöpfungskettenschritte sollen zur Vergleichbarkeit von Tätigkeiten über Branchen und Regionen hinweg beitragen. Sie helfen dann, relevante Experten und weitere Ressourcen im jeweiligen Feld sowie Synergiepotenziale zu identifizieren. Eine Lösung, die z.B. in der Preisgestaltung von Versicherungen gut funktioniert, könnte auch in der Preisgestaltung von Energieversorgungsunternehmen gut funktionieren`,
    // Topic Types
    "1_ps_tt_l1": "Problemstellung",
    "1_ps_tt_tt1": "Ergebnisorientiert, spezifisch, messbar, realistisch und zeitgebunden; oft in Frageform",
    "1_ps_tt_info1": "Eine Problemstellung sollte ergebnisorientiert, spezifisch, messbar, realistisch und zeitgebunden sein; typischerweise wird die Frageform genutzt. Lassen Sie Raum für Kreativität und stimmen Sie sie mit anderen Bereichen Ihrer Organisation ab \n\nBeispiel: \"Wie können wir den CO2-Fußabdruck unserer Schule innerhalb eines Jahres um mindestens 25 % im Vergleich zum Basisjahr 2020 verringern?\"",
    "1_ps_tt_l2": "Idee",
    "1_ps_tt_tt2": "Zielgerichtet, machbar, innovativ, relevant, systematisch, interessant",
    "1_ps_tt_info2":"Formulieren Sie die Idee zielgerichtet, machbar, innovativ, relevant, systematisch, interessant; berücksichtigen Sie ethische Aspekte\n\nBeispiel: \"Könnten wir Inspirationen aus der Tierwelt, z.B. aus den sozialen Strukturen von Bienen oder Ameisen, nutzen, um das Verhalten von Finanzmarktteilnehmern zu modellieren?\"",
    "1_ps_tt_l3": "Use Case",
    "1_ps_tt_tt3": "Wer die Lösung wofür und in welcher Weise nutzen wird",
    "1_ps_tt_info3":"Sagen Sie auf einer hohen Ebene, wer die Lösung verwenden wird, wofür sie verwendet wird und wie die Benutzer sie verwenden werden. \n\nBeispiel: \"Ein Versicherer möchte ein internes Suchtool entwickeln, das Call-Center-Agenten dabei hilft, die Antwortzeit zu verkürzen und die Qualität zu verbessern, indem es spezifische, zeitnahe Angaben zu anspruchsvollen Kundenanfragen liefert\"",
    "1_ps_tt_l4": "Forschungsfrage",
    "1_ps_tt_tt4": "Zielgerichtet, machbar, innovativ, relevant, systematisch, interessant",
    "1_ps_tt_info4":"Formulieren Sie die Idee zielgerichtet, machbar, innovativ, relevant, systematisch, interessant; berücksichtigen Sie ethische Aspekte \n\nBeispiel: \"Welche Inspirationen aus der Tierwelt, z.B. aus den sozialen Strukturen von Bienen oder Ameisen, können wir inwiefern nutzen, um das Verhalten von Finanzmarktteilnehmern zu modellieren?\"",
    "1_ps_tt_l5": "POV Statement & Designziel",
    "1_ps_tt_tt5": "Wer ist Nutzerpersona, was braucht sie, was sind die Gründe dafür? Daran anschließend die Frage, wie diese Nutzerbedürfnisse bedient werden können",
    "1_ps_tt_info5":"Definieren Sie, wer die Nutzerpersona ist, was sie braucht und was die Gründe dafür sind. Versuchen Sie, konsequent aus dem Blickwinkel der Persona zu denken. Stellen Sie dann eine Frage, die Raum für Kreativität lässt, aber ein Designziel formuliert, das spezifisch auf das Bedienen der Nutzerbedürfnisse ausgerichtet ist \n\nBeispiel: \"Ein Call Center-Mitarbeiter braucht Abwechslung in Bezug auf die Stimmung und den Inhalt von Kundenanfragen, weil sein Engagement und damit die Kundenzufriedenheit mit vielseitiger Arbeit steigen wird. Wie können wir also den Facettenreichtum in der Arbeit von Call Center-Mitarbeitenden erhöhen?\"",
    "1_ps_tt_l6": "Sonstiges",
    "1_ps_tt_l7": "Ziel",
    "1_ps_tt_tt7": "Ambitioniert, motivierend, wirkungsorientiert und auf die Erreichung übergeordneter Ziele hin ausgerichtet",
    "1_ps_tt_info7": "Formulieren Sie Ihr Ziel bzw. Objective ambitioniert, motivierend und wirkungsorientiert. Stellen Sie sicher, dass das Ziel auf die Erreichung übergeordneter Ziele hin ausgerichtet ist. \n\nBeispiel: \"Die höchste Kundenzufriedenheit am Markt erreichen\"",
    "1_ps_tt_l8": "Key Result",
    "1_ps_tt_tt8": "Was und wieviel Sie realistischerweise bis wann erreichen wollen, um zu einem übergeordneten Objective beizutragen",
    "1_ps_tt_info8": "Geben Sie an, was und wieviel Sie bis wann erreichen wollen; legen Sie Ergebnisse fest, die vernünftigerweise erreicht werden können. Lassen Sie Raum für Kreativität und vermeiden Sie es, einen Lösungsweg vorzugeben. Das Erreichen Ihres Schlüsselergebnisses soll Wert zum entsprechenden Objective und direkt oder indirekt zu den Gesamtzielen Ihrer Organisation beitragen \n\nBeispiel: \"Den RNPS-Wert bis Ende 2024 um 5 Punkte erhöhen\"",
    "1_ps_ttinfo": `Beispiel Problemstellung:
    Wie können wir den Ausstoß an Treibhausgasen unseres Gebäudes bis 2023 um 25 % reduzieren?
    \nBeispiel Use Case:
    Ein Versicherer will eine Lösung entwickeln, die Callcentermitarbeitenden dabei hilft, durch spezifische, zeitnahe Angaben zu erweiterten Kundenanfragen die Reaktionszeit zu verkürzen und die Qualität zu verbessern.
    \nBeispiel POV-Statement & Designziel: Ein Callcentermitarbeitender braucht Abwechslung in Bezug auf die Stimmung und den Inhalt von Kundenanfragen, denn sein Engagement und damit die Kundenzufriedenheit steigen mit vielseitiger Arbeit. Wie kann man also die Abwechslung in seiner Arbeit erhöhen?`,
    // ** Critical Definitions ** > 1-cd-
    "1-cd-hl": "Wichtige Definitionen",
    "1-cd-desc": "Führen Sie hier Begriffe auf, über deren Definition Klarheit bestehen sollte",
    "1-cd-table1": "Begriff",
    "1-cd-table2": "Definition",
    "1-cd-table3": "Kommentar",
    "1-cd-tooltip": "Insbesondere in Teamkonstellationen mit verschiedenen Hintergründen bietet es sich an, wichtige Begriffe klar zu definieren",
    // Guide
    // ** Problem Rating ** > 1-pr-
    "1-pr-hl": "Problemeinstufung",
    "1-pr-desc": "Stufen Sie Ihr Problem ein",
    "1-pr-r1t": "Einfach",
    "1-pr-r1d": "Umweltzustand in der Gegenwart ist klar, in der Zukunft (nahezu) sicher",
    "1-pr-r2t": "Kompliziert",
    "1-pr-r2d": "Umweltzustände in Gegenwart und/oder Zukunft sind mit mittlerer Unsicherheit behaftet",
    "1-pr-r3t": "Komplex",
    "1-pr-r3d": "Umweltzustände in Gegenwart und/oder Zukunft sind mit hoher Unsicherheit behaftet",
    "1-pr-r4t": "Chaotisch",
    "1-pr-r4d": "Umweltzustände in Gegenwart und Zukunft sind mit extrem hoher Unsicherheit behaftet",
    "1-pr-explanation1": "Beispiel:\n Ihr Auto entlang einer Anleitung notstarten",
    "1-pr-explanation2": "Beispiel:\n Ein Kraftwerk warten",
    "1-pr-explanation3": "Beispiel:\n Ein neues Automodell für einen Auslandsmarkt entwickeln ",
    "1-pr-explanation4": "Beispiel:\n Die Produktion angesichts eines plötzlichen Lockdowns umstellen",
    // Guide
    "1-pr-g1h": "Wie stuft man ein Problem, einen Anwendungsfall, ein POV-Statement mit Designziel etc. ein?",
    "1-pr-g1t": "Sie können sich an den Dimensionen der Unsicherheit über zukünftige Umweltzustände (Lösungsraum) und der Unsicherheit über den aktuellen Umweltzustand (der Kontext einschließlich bekannter und unbekannter Stakeholderbedürfnisse) orientieren",
    "1-pr-g2h": "Warum ist es relevant, ein Problem, einen Anwendungsfall, ein POV-Statement mit Designziel etc. einzustufen?",
    "1-pr-g2t": "Die Bestimmung der Art des Problems oder Anwendungsfalls ist entscheidend für die anschließende Wahl der Methode (z.B. muss Scrum nicht für komplizierte Probleme verwendet werden)",
    // ** Situation and Complications ** > 1-sc-
    "1-sc-hl": "Ausgangslage und Herausforderung",
    "1-sc-tooltip1": "Beispiel:\n Gebäude von 1980 ohne substantielle Renovierung seitdem. 1000 Beschäftigte als Nutzer im täglichen Durchschnitt. Gasheizung; Heizkosten von 70.000 €, Stromkosten von 20.000 € pro Jahr",
    "1-sc-prompt1": "Beschreiben Sie die Ausgangslage faktenbasiert",
    "1-sc-prompt2": "Beschreiben Sie die Herausforderungen, vor denen Sie stehen",
    "1-sc-sit": "Ausgangslage",
    "1-sc-comp": "Herausforderungen",
    // Tipps
    "1-sc-1t": "Fakten",
    "1-sc-1d": "Bleiben Sie neutral und vermeiden Sie Wertungen",
    "1-sc-2t": "Konsens",
    "1-sc-2d": "Es sollte Konsens aller Stakeholder über die Ausgangslage bestehen",
    "1-sc-3t": "Klarheit",
    "1-sc-3d": "Auch Außenstehende sollten Ihre Fakten und Definitionen nachvollziehen können",
    "1-sc-4t": "Hürden",
    "1-sc-4d": "Welche Hürden müssen beseitigt, bewältigt oder umgangen werden?",
    "1-sc-5t": "Risiken und Chancen",
    "1-sc-5d": "Nennen Sie nicht nur Risiken, sondern auch Chancen, die sich aus den Hürden ergeben",
    "1-sc-6t": "Wertung",
    "1-sc-6d": "Hier können Sie die Ebene der reinen Fakten hin zu balancierten Wertungen verlassen",
    "1-sc-7t": "Klarheit",
    "1-sc-7d": "Auch Außenstehende sollten ihre Wertungen nachvollziehen können",
    "1-sc-tooltip2": `Beispiel: 
    Eltern ziehen es vor, ihre Kinder aus Sicherheitsgründen zur Schule zu fahren, und setzen so ein Vorbild, das dem Ziel der Emissionssenkung entgegenläuft
    Die zur Meinungsbildung besonders relevante Gruppe der Lehrkräfte sieht im Durchschnitt einen geringen Handlungsbedarf, wäre aber offen für Veränderungen`,
    // ** Stakeholders ** > 1-sh
    "1-sh-hl": "Stakeholder",
    "1-sh-tooltip": "Je besser Sie verstehen, wer Ihre Stakeholder sind und weshalb, umso früher im Projekt können sie diese einbinden und zu Ko-Autoren machen",
    "1-sh-prompt": "Definieren Sie relevante interene und externe Teilnehmer",
    "impact-5": "Sehr Hoch",
    "impact-4": "Hoch",
    "impact-3": "Medium",
    "impact-2": "Niedrig",
    "impact-1": "Sehr Niedrig",
    // Table Header
    "sh-table-1": "Stakeholder",
    "sh-table-2": "Einfluss",
    "sh-table-3": "Betroffenheit",
    "sh-table-4": "Bedürfnisse und Erwartungen",
    "sh-table-5": "Begründung & Kommentar",
    // old
    "1-sh-table-1": "Stakeholder",
    "1-sh-table-2": "Impact",
    "1-sh-table-3": "Erklärung des Impacts",
    "1-sh-table-4": "Betroffenheit",
    "1-sh-table-5": "Bedürfnisse und Erwartungen",
    "1-sh-table-6": "Persönliche Präferenzen und Motive",
    "1-sh-table-7": "Beziehung zu diesem Stakeholder",
    // Tipps
    "1-sh-1t": "Balance",
    "1-sh-1d": "Ist ihre Sicht ausbalanciert?",
    "1-sh-2t": "Eisberg",
    "1-sh-2d": "Gelingt es Ihnen, unter die Wasseroberfläche zu schauen?",
    "1-sh-3t": "Pragmatische Vollständigkeit",
    "1-sh-3d": "Haben Sie die relevanten Stakeholder erfasst? Orientieren Sie sich an Impact und Betroffenheit",
    "1-sh-4t": "Vertraulichkeit",
    "1-sh-4d": "Schreiben Sie streng vertrauliche oder geheime Sachverhalte nur in die mit „VERTRAULICH - NUR KERNTEAM“ gekennzeichneten Felder",
    // Guide
    "1-sh-g1h": "Was ist ein Stakeholder?",
    "1-sh-g1t": "Eine Gruppe oder eine Person, die direkten oder indirekten Einfluss auf das Ergebnis Ihres Projekts und/oder ein Interesse daran hat",
    "1-sh-g2h": "Was kann der Einfluss eines Stakeholders bewertet werden?",
    "1-sh-g2t": "Der Einfluss eines Stakeholders beschreibt seine Fähigkeit, das Projektergebnis zu beeinflussen",
    "1-sh-g3h": "Was beeinflusst den Grad der Betroffenheit eines Stakeholders?",
    "1-sh-g3t": "Der Grad der Betroffenheit wird in der Regel durch die Auswirkungen des Projekts auf den Stakeholder und/oder das Ausmaß, in dem er für das Ergebnis verantwortlich gemacht wird, bestimmt",
    "1-sh-g4h": "Wie beschreibt man die Bedürfnisse eines Stakeholders?",
    "1-sh-g4t": "Benennen Sie, was der Stakeholder angegeben hat oder was aufgrund seiner Interessenlage vernünftigerweise angenommen werden kann. Geben Sie nach Möglichkeit Einblicke in die Gründe für die Bedürfnisse",
    // ** Key Results (Definition of Done) ** >1-kr-
    "1-kr-hl": "Kernresultate",
    "1-kr-prompt": "Was muss gegeben sein, damit Ihr Problem als gelöst betrachtet werden kann?",
    "1-kr-tooltip": "Ihre Kernresultate müssen alle erreicht werden, damit Ihr Problem als gelöst gilt. Kernresultate sollten möglichst immer, müssen aber nicht quantitative Aspekte enthalten",
    "1_kr_quan":"Messbar?",
    "1_kr_addkey":"+ Kernresultat hinzufügen",
    // Tipps
    "1-kr-1t": "Spezifisch",
    "1-kr-1d": "Was genau möchten Sie erreichen, z.B. „den NPS erhöhen“. Definieren Sie Resultate, nicht Leitplanken",
    "1-kr-2t": "Messbar",
    "1-kr-2d": "Setzen Sie ein quantitatives Resultat, z.B. „um 2 %Pkt.“",
    "1-kr-3t": "Realistisch",
    "1-kr-3d": "Stellen Sie sicher, dass die Erreichung der Resultate realistisch ist",
    "1-kr-4t": "Zeitgebunden",
    "1-kr-4d": "Setzen Sie einen Zeitrahmen, innerhalb dessen das Resultat erreicht werden soll, z.B. „innerhalb von 6 Monaten“",
    "1-kr-5t": "Ergebnisoffen",
    "1-kr-5d": "Vermeiden Sie auch hier eine Lösungsvorgabe. Lassen Sie Raum für Kreativität",
    "1-kr-6t": "Wertbeitragend",
    "1-kr-6d": "Stellen Sie einen möglichst direkten, messbaren Beitrag zu den übergeordneten Unternehmenszielen sicher",
    // Guide
    "1-kr-g1h": "Was ist die Beziehung zwischen Unternehmenszielen (goals), Zielen (objectives) und Ergebnissen (key results)?",
    "1-kr-g1t": `Unternehmensziele sind typischerweise breit und langfristig angelegt, wie z.B. \"die Nr. 1 auf dem Markt zu werden\". Objectives sind ambitionierte, motivierend und wirkungsorientiert formulierte Ziele, die auf die Erreichung der Unternehmensziele (und ggf. auf Ziele weiterer übergeordneter Hierarchieebenen) hin ausgerichtet sind. Die Key Results wiederum sind den Objectives direkt zuzuordnen und sollen Meßbarkeit herstellen`,
    "1-kr-g1l": "",
    "1-kr-g2h": "Was ist der Unterschied zwischen einem Ziel und einer Randbedingung?",
    "1-kr-g2t": `Wenn \"mehr (oder weniger) ist besser\" gilt, dann sprechen Sie wahrscheinlich von einem Ergebnis, z.B. \"mindestens den dritten Platz im Rennen belegen\". Im Gegensatz dazu ist eine Randbedingung wie eine Begrenzung auf einer Rennbahn: Sie müssen sie einhalten, um nicht disqualifiziert zu werden, aber Sie können den gesamten Ihnen zugestandenen Platz nutzen, z.B. \"keine wesentliche Beanstandung der Lösung durch die Aufsichtsbehörde\"`,
    "1-kr-g3h": "Entsprechen eine Problemstellung, ein Use Case oder ein Designziel eher der Objective- oder der Key Results-Ebene?",
    "1-kr-g3t": `Das hängt vom Kontext ab. Oft ist es einfacher, auf der Objective-Ebene zu beginnen und dann Key Results zu verwenden, um den Fortschritt messbar zu machen. Wenn die Problemstellung bereitssehr spezifisch und thematisch eng gefasst ist, können Sie sie als Key Result formulieren`,
    "1-kr-g4h": "Wie viele Schlüsselergebnisse sollten Sie pro Objective formulieren?",
    "1-kr-g4t": `Versuchen Sie, die Anzahl gering zu halten (<5) - das hilft Ihrem Team, sich zu fokussieren und schränkt den Lösungsraum nicht zu sehr ein`,
    "1-kr-g5h": "Wie sollten Sie Ihre Schlüsselergebnisse formulieren?",
    "1-kr-g5t": `(1) Spezifisch: Geben Sie an, was Sie erreichen wollen, z.B. \"Steigerung des Bruttogewinns\", \"Verringerung der Reaktionszeit am Telefon\", \"Erhöhung des NPS-Marktrankings\"; (2) Messbar: Wie viel wollen Sie erreichen, z.B. \"um 50%\", \"runter auf 15 Sekunden\", \"um mindestens 5 Positionen, mindestens Rang 5\"; (3) Realistisch: Legen Sie Ergebnisse fest, die vernünftigerweise erreicht werden können, z.B. anhand eines Marktvergleichs oder von Benchmarks (\"Konkurrent XY hat im letzten Jahr den gleichen Erfolg erzielt\"); (4) Zeitgebunden: Bis wann soll das Ergebnis erreicht werden? Z.b. \"bis 2030\", \"innerhalb der nächsten 2 Jahre\"; (5) Offen: Lassen Sie Raum für Kreativität und vermeiden Sie es, einen Lösungsweg vorzugeben, indem Sie Formulierungen wie \"durch die Umsetzung von ...\" oder \"unter Verwendung von ...\" verwenden; (6) Wertbeitrag: Das Erreichen Ihres Schlüsselergebnisses sollte zum entsprechenden Objective und direkt oder indirekt zu den Gesamtzielen Ihrer Organisation beitragen; z.B. würde \"den Umsatz um 10% steigern\" zum Ziel \"Wachstum\" beitragen`,
    "1-kr-g6h": "Warum sollten Schlüsselergebnisse quantifiziert werden?",
    "1-kr-g6t": `(1) Es hält Ihr Team fokussiert und ausgerichtet; (2) Es reduziert somit die Komplexität in Ihrem Tagesgeschäft und in Ihrer Kommunikation; (3) Die Messbarkeit wird erleichtert; dies reduziert die Wahrscheinlichkeit von späteren Konflikten, wenn es um die Bewertung geht; (4) Es schafft Inspiration und Sinn, besonders wenn das Schlüsselresultat logisch direkt mit Ihren Organisationszielen verknüpft ist`,
    "1-kr-g7h": "In welchen Situationen ist eine Quantifizierung von Schlüsselergebnissen nicht sinnvoll?",
    "1-kr-g7t": `Überlegen Sie kurz, ob eine quantifizierte Messgröße identifiziert werden kann, selbst für einen eher qualitativen Fall (z.B. unter Verwendung von Prozentsätzen oder eines Indexes, der ein qualitatives Konzept wie Demokratie, Gerechtigkeit, ... bezeichnet). Wenn ja, müssen Sie die Messgröße sorgfältig definieren. Wenn dies keinen Sinn ergibt, lassen Sie den quantitativen Teil aus und verwenden Sie gut definierte qualitative Schlüsselergebnisse`,
    "1-kr-g8h": "Warum eine Baseline?",
    "1-kr-g8t": `Eine Baseline markiert einen soliden, idealerweise unumstrittenen Ausgangspunkt (z.B. Werte aus dem testierten Jahresabschluss) - vermeiden Sie daher Zahlen, die anfällig für Veränderungen oder Diskussionen sind. Sie bildet die Grundlage für die Messbarkeit und verringert die Wahrscheinlichkeit späterer Konflikte bei der Bewertung`,
    "1-kr-g9h": "Wie geht man mit Konstellationen mit mehreren Schlüsselergebnissen um?",
    "1-kr-g9t": `Während Sie die Anzahl Ihrer Schlüsselergebnisse so weit wie möglich begrenzen sollten, um die Komplexität zu reduzieren, wird es oft mehr als ein Schlüsselergebnis geben. Es sollten jedoch möglichst Situationen vermieden werden, in denen Schlüsselergebnisse widersprüchlich sind (\"Maximale Preiserhöhungen bei gleichzeitiger Minimierung der Stornoquote\"). Diese Konflikte können durch Umwandlung eines Schlüsselergebnisses in eine Nebenbedingung vermieden werden: \"Maximierung des Zusatzertrags aus Preiserhöhungen bei Einhaltung einer maximalen Abwanderungsrate von 10%\"`,
    // ** Primary Metric, Kernmetriken **
    "1-pm-title": "Kernmetriken",
    "1-pm-primary": "Primäre Metrik",
    "1-pm-secondary": "Sekundäre Metrik",
    "1-pm-tertiary": "Tertiäre Metrik",
    "1-pm-button1": "Sekundäre Metrik",
    "1-pm-button2": "Tertiäre Metrik",
    "1-pm-prompt1": "Legen Sie die Baseline für Ihr quantitatives Kernresultat fest",
    "1-pm-label1": "Größe und Zeitpunkt",
    "1-pm-label2": "Ausgangswert",
    "1-pm-label3": "Einheit",
    "1-pm-tooltip1": `Beispiel\n\nGröße und Zeitpunkt: Treibhausgasemissionen zum 31.12.2020\nWert: 2000\nEinheit: Tonnen`,
    "1-pm-tooltip1-ref": "Graphische Visualisierung",
    "1-pm-tooltip2": "Auch wenn Ihr Problem multidimensional ist, sollten Sie ein primäres quantitatives Kernresultat angeben.\n Dieses wird den Fokus Ihres Teams entlang des gesamten Problemlösungsprozesses erhöhen.\n Sie können danach auch eine sekundäres und ggf. ein drittrangiges Ziel setzen",
    "1-pm-prompt2": "Setzen Sie das quantitative Kernresultat fest",
    "1-pm-label4": "Zielwert",
    "1-pm-label5": "Delta",
    "1-pm-prompt3": "Benennen Sie das Unternehmensziel zu dem Ihr primäres quantitatives Kernresultat beiträgt",
    "1-pm-goalLabel": "Unternehmensziel",
    "1-pm-percentage": "Prozent",
    "1-pm-tooltip3": `Beispiel:\n Eine Wachstumsinitiative mit einem Kernresultat von 1000 EUR zahlt typischerweise zu 100% auf das Ziel „Wachstum“ ein.
    Sie können ein Sekundärresultat benennen, wenn die Lösung Ihres Problems auf mehr als ein Ziel einzahlt`,
    "1_PM_metrics":"Metriken",
    // Tipps 
    "1-pm-1t": "Zeitpunktbezogen",
    "1-pm-1d": "Die Baseline sollte auf ein Datum bezogen sein",
    "1-pm-2t": "Messbar",
    "1-pm-2d": "Die Baseline erfordert eine klar definierte Größe mit einer Einheit",
    "1-pm-3t": "Unstrittig",
    "1-pm-3d": "Die Baseline sollte möglichst wenig Anlass zu Meinungsstreit geben",
    "1-pm-4t": "Verzahnt",
    "1-pm-4d": "Die Baseline sollte möglichst dieselbe Größe und Maßeinheit aufweisen wie das hier relevanteste Konzernziel, oder aber überleitbar sein",
    "1-pm-5t": "Quantitatives Kernresultat",
    "1-pm-5d": "Eines Ihrer Kernresultate, am besten das wichtigste, welches quantifizierbar ist",
    "1-pm-6t": "Wertbeitrag",
    "1-pm-6d": "Möglichst direktes, nachvollziehbares Einzahlen auf die übergeordneten Unternehmensziele",
    "1-pm-7t": "Konsistenz",
    "1-pm-7d": "Gleiche Größe und Einheit wie die Baseline",
    "1-pm-8t": "Unternehmensziel ",
    "1-pm-8d": "Z.B. das Unternehmensziel „Wachstum auf 10 Mrd. EUR Umsatz bis 2023“, falls Ihr primäres quantitatives Kernresultat wachstumsbezogen ist. Die Unternehmensziele können zentral vorgegeben sein",
    "1-pm-9t": "Wertbeitrag ",
    "1-pm-9d": "Geben Sie an, welcher Anteil Ihres quantitativen Kernresultats auf das identifizierte Unternehmensziel einzahlt  - häufig sollten das 100% sein. Lesehilfe: “Wir erhöhen den Gewinn um 50 EURM, und 90% davon kommen dem Gewinnziel unseres Unternehmens zugute. 10% zählen nicht, weil unser Konzern nur Gewinne aus Europa gelten lässt”  ",
    "1-pm-10t": "Mehrdimensionalität",
    "1-pm-10d": "Prüfen Sie, ob Sie ggf. ein sekundäres quantitatives Kernresultat erstellen möchten, das auf ein weiteres Konzernziel einzahlt (z.B. eine Wachstumsinitiative, die auch Ertragspotentiale hat)",
    //GUIDE
    "1-pm-link": "Graphische Visualisierung",
    "1-pm-g1h": "Warum eine Baseline?",
    "1-pm-g1t": `•	Die Baseline ist gleichsam die Startlinie Ihres Projekts. Sie sollte daher objektiv und nachvollziehbar sein und nicht zu Diskussionen führen
    \n•	Die Baseline ist Voraussetzung für Messbarkeit Ihres Erfolgs
    \n•	Sie verringert die Wahrscheinlichkeit späterer Konflikte bzgl. des Erfolgs Ihres Projekts`,
    "1-pm-g2h": "Ist eine Baseline möglicherweise nicht sinnvoll?",
    "1-pm-g2t": `•	Reflektieren Sie auch im Falle qualitativer Fragestellungen, ob Sie nicht einen quantitativen Startpunkt in Form einer Baseline setzen können (z.B. einen Prozentsatz oder einen Index, der abstrakte Konzepte wie Gerechtigkeit operationalisiert, …)
    \n•	Achten Sie in diesem Fall auf eine saubere Definition der verwendeten Größe
    \n•	Ergibt dies immer noch keinen Sinn, verzichten Sie auf Baseline und quantitatives Kernresultate`,
    "1-pm-g3h": "Was ist der Unterschied zwischen Unternehmenszielen, Zielen, Kernresultaten, und Leitplanken?",
    "1-pm-g3t": `•	Unternehmens- oder Organisationsziele (engl.: goals) sind typischerweise breit gefasst und zielen auf die lange Frist ab, z.B. „die Nr. 1 im Markt zu werden nach Umsatz“
    \n•	Ein Ziel (engl.: objective) ist spezifischer und gibt eine Richtung vor, mit der das zugehörige Unternehmensziel erreicht werden soll 
    \n•	Ihre Problemstellung enthält Ihr Ziel in Frageform. Das Ziel sollte inspirierend sein und möglichst direkt und messbar auf die Organisationsziele einzahlen
    \n•	Ihre Kernresultate (engl.: key results) können quantitative und/oder qualitative Elemente enthalten. Alle müssen erreicht werden, damit Ihr Problem als gelöst und Ihr Ziel somit als erreicht gilt
    \n•	Eines Ihrer Resultate sollte das primäre quantitative Kernresultat sein, z.B. „45 Mio. EUR an Neugeschäft bis Ende des Jahres“
    \n•	Wenn Sie ein Resultat verfolgen, maximieren (oder minimieren) Sie eine Zielgröße: Mehr (oder weniger) ist besser
    \n•	Während Sie das tun, müssen Sie jedoch Leitplanken beachten, z.B. Gesetze oder Konzernvorgaben. Tun Sie das nicht, gehen Sie möglicherweise erhebliche Risiken ein. Gleichzeitig steht es Ihnen jedoch frei, den kompletten durch die Leitplanken begrenzten Raum zu nutzen`,
    "1-pm-g4h": "Gibt es Situationen, in denen ein Team mehr als ein Kernresultat braucht?",
    "1-pm-g4t": `•	All Kernresultate müssen erreicht werden, damit Ihr Problem als gelöst betrachtet werden kann
    \n•	Während Sie die Komplexität durch die Wahl möglichst weniger Kernresultate gering halten können, wird es im Regelfall mehr als eines geben (müssen)
    \n•	Es kann sogar Abwägungssituationen geben, in denen Kernresultate potentiell miteinander konfligieren (z.B. die Erhöhung der Preise mit der Verringerung der Stornoquoten) 
    \n•	Solche Konfliktsituationen sollten vermieden werden, z.B. indem Kernresultate in Leitplanken transformiert werden: Die Erhöhung der Preise darf dann nur noch mit einer Stornoquote von maximal 10% einhergehen, bzw. umgekehrt`,
    "1-pm-g5h": "Was tun bei einer großen Zahl an Kernresultaten?",
    "1-pm-g5t": `•	Halten Sie die Komplexität durch die Wahl möglichst weniger Kernresultate gering
    \n•	Transformieren Sie Kernresultate in Leitplanken, wenn diese nicht der Regel „Mehr (oder weniger) ist besser“ gehorchen
    \n•	Erstellen Sie Leitplanken im nächsten Schritt: Im Gegensatz zu einem Kernresultat muss eine Leitplanke lediglich nicht verletzt werden`,
    "1-pm-g6h": "Warum ein quantitatives Kernresultat?",
    "1-pm-g6t": `•	Es erhöht den gedanklichen Fokus Ihres Teams
    \n•	Es reduziert Komplexität im Alltag und in der Kommunikation
    \n•	Es erhöht die Objektivität und Messbarkeit Ihres Erfolgs durch Abgleich der IST-Werte mit dem quantitativen Kernresultat
    \n•	Es kann inspirierend und sinnstiftend wirken, vor allem wenn es eine direkte Verknüpfung zu Ihren Unternehmenszielen gibt
    \n•	Zu beachten: So wichtig das quantitative Kernresultat auch ist, so ist es nur notwendig, nicht hinreichend für Ihren Erfolg (auch die weiteren Kernresultate und die Leitplanken müssen Sie beachten)`,
    "1-pm-g7h": "Wann ist ein sekundäres oder sogar drittrangiges quantitatives Kernresultat angebracht?",
    "1-pm-g7t": `•	Manchmal kann zur Lösung eines Problems die Erreichung von mehr als nur einem quantitativen Kernresultat erforderlich sein oder aber ein positiver Nebeneffekt sein
    \n•	Der komplexitätsreduzierende Weg über die Formulierung einer Randbedingung ist nicht immer sinnvoll, z.B. in klassischen „Minimiere das Risiko-maximiere den Ertrag-Situationen“
    \n•	Auch ist es sinnvoll, verschiedene Kernresultate direkt und transparent mit verschiedenen Unternehmenszielen zu verknüpfen
    \n•	In solchen Fällen können sekundäre quantitative Kernresultate sinnvoll sein
    \nBeispiel Pricing 
    • 3 EURM an Umsatz durch Pricing ➔ Einzahlen zu 100% auf Ziel „Wachstum“ 
    • 1 EURM an zusätzlichem Ertrag aus diesen Pricing-maßnahmen ➔ Einzahlen zu 100% auf Ziel „Profitabilität“`,
    "1-pm-g8h": "Was tun, wenn Quantifizierung keinen Sinn ergibt?",
    "1-pm-g8t": `•	Reflektieren Sie auch im Falle qualitativer Fragestellungen, ob Sie nicht einen quantitativen Startpunkt in Form einer Baseline (z.B. einen Prozentsatz, einen Index, der abstrakte Konzepte wie Gerechtigkeit operationalisiert, …) sowie ein quantitatives Kernresultat setzen können
    •	Achten Sie in diesem Fall auf eine saubere Definition der verwendeten Größe
    •	Ergibt dies immer noch keinen Sinn, verzichten Sie auf Baseline und quantitatives Kernresultat
    `,
    // ** Constraints ** > 1-c-
    "1-c-hl": "Leitplanken",
    "1-c-tooltip": `Während ein Kernresultat der Regel „mehr (oder weniger) ist besser“ gehorcht, geht es bei einer Leitplanke darum, diese lediglich einzuhalten. 
    Geschieht dies nicht, können z.B. die erzielten Resultate entwertet werden (wie ein Tor, das aufgrund der verletzten Abseitsregel nicht zählt)`,
    "1-c-prompt": "Welche Leitplanken bzw. Randbedingungen gelten?",
    // Tipps
    "1-c-1t": "Kohärenz",
    "1-c-1d": "Leitplanken sind keine Kernresultate: Es gilt also nicht „Mehr (oder weniger) ist besser“",
    "1-c-2t": "Minimale Hürde",
    "1-c-2d": "Beschränken Sie Ihre Leitplanke auf das erforderliche Minimum, um den Lösungsraum möglichst weit zu belassen",
    "1-c-3t": "Überprüfbarkeit",
    "1-c-3d": "Die Leitplanke und ihre Bindungskraft sind überprüfbar, z.B. durch einen Blick in das Gesetzbuch oder durch die Bestätigung eines Stakeholders",
    "1-c-table1": "Leitplanke",
    "1-c-table2": "Bindungskraft",
    "1-c-table3": "Kommentar",
    "1-c-table-rating1": "Weich",
    "1-c-table-rating2": "Mittel",
    "1-c-table-rating3": "Hart",
    // **OVERVIEW** > 1-ov-
    "1-ov-title": "Übersicht",
    "1-ov-confiLabel": "Schutzstufe",
    "1-ov-refine": "Verfeinern",
    "1-ov-ref-tool": "[Platzhalter] Verfeinern Sie Ihr Thema ...",
    "1-ov-visib": "Sichtbarkeit",
    "1-ov-pri": "Primär",
    "1-ov-sec": "Sekundär",
    "1-ov-ter": "Tertiär",
    "1-ov-sit": "Ausgangslage",
    "1-ov-com": "Herausforderungen",
    "1-ov-def": "Definitionen",
    // Tabview, Project View 2-
    "2-probdef": "Problemdefinition",
    "Problem Definition": "Problemdefinition",
    "2-probstru": "Problemstruktur",
    "2-iss": "Priorisierte Themen",
    "2-app": "Optionen",
    "2-decspa": "Entscheidungsraum",
    "2-com": "Executive Summary",
    // ** Share View share-
    "share-title": "Teilen und Feedback erhalten",
    "share-text1": `Sie haben nun die Möglichkeit, Ihre Problemdefinition innerhalb und außerhalb der Grenzen Ihrer Organisation zu teilen.
    \nDurch die enge Einbindung von Inputgebern mit diversen Perspektiven können Sie eine inhaltliche und zwischenmenschliche Bereicherung und somit eine höhere Ergebnisqualität erzielen.
    \nAlle Teilnehmer werden auf die Vertraulichkeit verpflichtet.`,
    "share-invited": "Eingeladene Personen",
    "share-invite-tooltip": `Bitte tragen Sie die E-Mail Adressen der einzuladenden Personen ein.
    \nTrennen Sie mehrere Adressen mit einem Komma.`,
    // GUIDE
    "share-guide-1": `Wozu dient die Teilen-Funktion?`,
    "share-guide-2": `•	Die Teilen-Funktion ist ein Herzstück dieses Programms, weil Sie hier die Diversität der Perspektiven innerhalb und außerhalb Ihrer Organisation nutzen können
    \n•	Sie können zu jedem Zeitpunkt beliebig viele Personen in Ihre Problemlösungssituation einbeziehen
    \n•	Sie können früh im Prozess sicherstellen, dass Ihre Problemdefinition auf stabilem Grund steht`,
    "share-guide-3": `Wie wird die Teilen-Funktion genutzt?`,
    "share-guide-4": `•	Fügen Sie beliebig viele Mailadressen hinzu
    \n•	Ihre benannten Inputgeber erhalten eine Mail und können auf Ihre Problemdefinition zugreifen
    \n•	Sichten Sie die eingehenden Kommentare. Es ist empfehlenswert, alle Kommentare zu beantworten, auch dann, wenn Sie diese inhaltlich nicht berücksichtigen möchten
    \n•	Bitte beachten Sie: Ihre Inputgeber können nur auf das Endprodukt „Problemdefinition“ zugreifen, nicht auf weitere Endprodukte, die Sie noch erstellen. Dies ist aus Gründen der Vertraulichkeit so geregelt
    `,
    "share-guide-5": `Wie werden die Inputgeber informiert?`,
    "share-guide-6": `•	Die Inputgeber erhalten allgemeine Informationen und die Einladung, sich an Ihrer Problemlösung zu beteiligen
    \n•	Die Inputgeber erhalten eine Datenschutzerklärung mit der Bestätigung, dass ihre personenbezogenen Daten stets entsprechend der Datenschutzgrundverordnung (EU) 2016/679 behandelt werden
    \n•	Die Inputgeber erhalten und akzeptieren eine Vertraulichkeitserklärung: Hiermit erklären sie, dass sie vertrauliche Informationen, die ihnen zugänglich gemacht werden, auch vertraulich behandeln werden. Vertrauliche Informationen im Sinne dieser Vereinbarung sind sämtliche Informationen (ob schriftlich, elektronisch, mündlich, digital verkörpert oder in anderer Form), die dem Inputgeber durch Sie zur Verfügung gestellt werden
    \n•	Die Inputgeber erhalten und akzeptieren die Allgemeinen Geschäftsbedingungen. Hier finden sich die Grundlagen zu Zugriffsberechtigung, Schulung, Support, Datenspeicherung, Leistungserbringung, SLA und Störungsmanagement, Nutzungsrechten, Weiterentwicklungen, Mitwirkungspflichten, Kundendaten und Freistellung von Ansprüchen Dritter, Herausgabe und Löschung von Daten, Verarbeitung personenbezogener Daten und Auftragsverarbeitung, Vertraulichkeit sowie weiteren allgemeinen Bestimmungen. Auch finden sich hier Verweise auf weitere Anlagen.
    `,
    // ** MODALS > m-
    "m-saveNewProblem-title": "Ihre Problemdefinition wurde gespeichert",
    "m-saveNewProblem-body": `Sie haben die erste Etappe geschafft!\n Sie haben nun die Möglichkeit, Ihre Problemdefinition innerhalb und außerhalb der Grenzen Ihrer Organisation zu teilen.\n
    Durch die enge Einbindung von Inputgebern mit diversen Perspektiven können Sie eine inhaltliche und zwischenmenschliche Bereicherung und somit eine höhere Ergebnisqualität erzielen.`,
    "required-hoverInfo1": "Bitte füllen Sie alle Pflichtfelder und wählen Sie eine Community aus."
    //////////////////////// END OF 'Create Problem'
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}

export default problemDefinitionDict;