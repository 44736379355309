import ElementWithHoverinfo from "components/ElementWithHoverinfo";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ProptTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function DeleteIconButton({ handleDelete, id }) {
    const { t } = useTranslation(['common'])

    return (
        <ElementWithHoverinfo
            id={`deleteIconButton_${id}`}
            active
            iconColor='text-red-700'
            icon={faTrash}
            tooltip={t('Delete')}
            onClick={handleDelete}
        />
    );
}

DeleteIconButton.propTypes = {
    id: ProptTypes.string,
    handleDelete: ProptTypes.func.isRequired
}

export default DeleteIconButton;