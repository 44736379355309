const commonDict = {
    // General translations without key
    "Create account": "Account erstellen",
    "Create": "Erstellen",
    "Log in": "Einloggen",
    "Delete": "Löschen",
    "deleted": "gelöscht",
    "Share": "Teilen",
    "Save": "Speichern",
    "Cancel": "Abbrechen",
    "Edit": "Bearbeiten",
    "del": "Löschen",
    "Close": "Schliessen",
    "Archive": "Archivieren",
    "Back": "Zurück",
    "Next": "Weiter",
    "or": "oder",
    "Invite": "Einladen",
    "Date": "Datum",
    "Last modified": "Zuletzt Aktualisiert",
    "Comment": "Kommentar",
    "Comments": "Kommentare",
    "Contributor": "Beitragende",
    "Download": "Herunterladen",
    "All rights reserved": "Alle Rechte vorbehalten",
    "selected": "ausgewählt",
    "Press enter to add new tag": "Drücken Sie Enter um neue Tags hinzuzufügen",
    "This field is required": "Dies ist ein Pflichtfeld",
    "Participants": "Teilnehmer",
    "Problems": "Probleme",
    "Topics": "Themen",
    "New Topic": "Neues Thema",
    "About & Legal": "Informationen & Rechtliches",
    "Terms of Use": "Nutzungsbedingungen",
    "Privacy Notice": "Datenschutzerklärung",
    "Email address": "Email Adresse",
    "Post": "Absenden",
    "Created by": "Erstellt von",
    "edited":"bearbeitet",
    "Join":"Beitreten",
    "Join Community":"Community Beitreten",
    "Password":"Passwort",
    "pre1":'vor',
    "just now":"jetzt",
    "minutes ago":"Minuten",
    "hours ago":"Stunden",
    "days ago":"Tagen",
    "yesterday at":"Gestern um",
    "Reply":"Antworten",
    "Last Refresh":"Zuletzt aktualisiert",
    "Newest":"Neuste",
    "Oldest":"Älteste",
    "Alphabetical":"Alphabetisch",
    "New Topic Settings":"Einstellungen für 'Neues Thema'",
    "Save and Close":"Speichern und Schließen",
    "Go to dashboard":"Zum Dashboard",
    "No documents":"Keine Dokumente",
    "No links":"Keine Links",
    "Select Status":"Status auswählen",
    "Open":"Offen",
    "Implemented":"Implementiert",
    // with key
    "signIn": "Loggen Sie sich ein",
    "loglink": "Login Link erhalten",
    "c-up": "Dies ist für mich relevant / hilfreich",
    "c-down": "Dies ist für mich nicht relevant / hilfreich",
    "comdel":"Kommentar gelöscht",
    "docs":"Zugehörige Dokumente",
    // Login Success
    "log-suc": "Erfolgreich",
    "log-check": "Bitte prüfen Sie Ihr E-Mail Postfach",
    "log-text": "Eine E-Mail mit einem Log-In Link wurde an die angegebene E-Mailadresse gesendet.",
    "reg-suc": "Registrierung erfolgreich",
    "reg-text": "Eine E-Mail mit einem Registrier-Link wurde an die angegebene E-Mailadresse gesendet.\nBitten prüfen Sie gegebenenfalls den Spam-Ordner",
    // Comments
    'com-add': 'Kommentar hinzufügen',
    'comment-empty': 'Schreiben Sie den ersten Kommentar!',
    // Document titles
    "doc-princ": "Grundsätze",
    "doc-legal": "Impressum",
    "doc-pnw": "Datenschutzerklärung",
    "doc-tou": "Nutzungsbedingungen",
    "doc-pnu": "Datenschutzerklärung",
    "doc-ref": "Verweis auf Verantwortlichen",
    "Contact": "Kontakt",
    "com-contact": `Wenden Sie sich bei Bedarf bitte an unseren Kundendienst:
    \nsupport@solverint.com 
    \nWir helfen Ihnen gerne weiter, z.B. bei technischen Fragen oder Fragen zur Benutzerfreundlichkeit. Auch sind wir dankbar für Feedback jeglicher Art.  
    \nBitte beachten Sie jedoch, dass die Inhalte und Bedingungen Ihrer Veranstaltung durch Ihren Vertragspartner festgelegt werden. Dieser ist “Verantwortlicher”, z.B. im Sinne der Datenschutzgrundverordnung. 
    \nSie finden die Kontaktdaten des Verantwortlichen sowie von dessen Datenschutzbeauftragten im Abschnitt “Informationen und Rechtliches” unter “Verweis auf Verantwortlichen”. `,
    // Notifications
    "not-1":"Keine Benachrichtigungen",
    "not-com":"Neuer Kommentar bei",
    "not-rep":"Neue Antwort bei",
    "not-topic":"Neues Thema",
    "not-match-topic":"Das Thema passt zu deinem Profil",
    "not-profile":"Neues Profil-Match bei",
    "not-modal1":"Willkommen",
    "not-modal2":"Sie haben",
    "not-modal3":"ungelesene Benachrichtigungen.",
    "organisation-role-tooltip": `Der Owner kann Administratoren ernennen.\n
    Owner und Administratoren können eigene Inhalte erstellen und fremde Inhalte managen.`,
    "community-role-tooltip": `Der Owner kann Administratoren ernennen.\n
    Owner und Administratoren können eigene Inhalte erstellen und fremde Inhalte managen.\n
    Editoren können Themen in allen Clustern sichten, erstellen und kommentieren.\n
    Viewers nur Inhalte sichten`,
    "project-role-tooltip": `Der Owner kann Administratoren ernennen.\n
    Owner und Administratoren können eigene Inhalte erstellen und fremde Inhalte managen.\n
    Editoren können Themen innerhalb des Clusters sichten, erstellen und kommentieren.\n
    Viewers nur Inhalte sichten`,
    "problem-role-tooltip": `Der Owner kann Administratoren ernennen.\n
    Owner und Administratoren können eigene Inhalte erstellen und fremde Inhalte managen.\n
    Editoren können das Thema sichten und kommentieren.\n
    Viewers nur Inhalte sichten`,
}

export default commonDict;