import { faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Displays a general AI button at the bottom right corner of the screen 
 * TODO add click action either in parent or in another component for general AI content
 * TODO delete old 'ButtonAi.js' when this component is implemented
 */
function GeneralAiButton({onClick}) {
    return (
        <div className="fixed bottom-10 right-10 z-[100] bg-primary-200 p-2 rounded-full cursor-pointer flex items-center justify-center group transition-all transform duration-300 hover:bg-primary-300">
            <FontAwesomeIcon className="text-primary-900 group-hover:text-primary-900 group-hover:rotate-6 active:scale-110 group-active:rotate-[25deg] h-7 p-2" icon={faMagicWandSparkles} />
        </div>
    );
}

export default GeneralAiButton;