import { faChainBroken, faDownload, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

/**
 * Displays row for downloadable files
 */
function FileDownloadableRow({ filename, text, link, status }) {

    // console.log(filename, text, link, status)

    return (
        <a target='_blank'
            href={link}
            className='flex justify-between items-center group hover:bg-primary-200 p-1 rounded-md'>
            <div className='flex items-center'>
                {status == 'UPLOADED' && link
                    ? <FontAwesomeIcon className='text-gray-700 pr-2' icon={faPaperclip} />
                    : <FontAwesomeIcon className='text-red-700 pr-2' icon={faChainBroken} />}
                <span className='w-48'>{filename}</span>
                <span className='text-gray-600 italic'>{text}</span>
            </div>
            {link && <FontAwesomeIcon className='group-hover:text-primary-800 text-primary-400 cursor-pointer transform hover:scale-110' icon={faDownload} />}
        </a>

    );
}

FileDownloadableRow.propTypes = {
    filename: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string,
}

export default FileDownloadableRow;