function TooltipBase({ width, className, children, text }) {
    return (
        <div className={`select-none mt-2 p-1 rounded-md text-center
        text-primary-700 text-sm bg-primary-100 whitespace-pre-line shadow-md border border-primary-200
        ${width || "w-60"}
        ${className}
        `}>
            {children} {text}
        </div>
    );
}

export default TooltipBase;