import { faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';

/**
 * To display button at right side, parent needs to be 'relative' 
 * TODO Delete this component when the 'general Ai Button' is implemented
 */
function ButtonAi({onClick}) {

    return (
        <div className="absolute cursor-pointer group transition-all -right-10 top-0 bg-primary-100 hover:bg-primary-200 rounded-md p-2 h-full flex items-center transform duration-300"
        onClick={onClick}>
            <FontAwesomeIcon className="text-primary-900 group-hover:text-primary-900 group-hover:rotate-6 active:scale-110 group-active:rotate-[25deg]" icon={faMagicWandSparkles} />            
        </div>
    );
}

ButtonAi.propTypes = {
    onClick: PropTypes.func.isRequired
}

export { ButtonAi };